<template>
  <el-drawer
       class="drawerCommon"
      :visible.sync="drawer"
      size="800px" :with-header="false" @close="closeAction">
    <el-table
        :data="tableDate" border
        class="tableMember"
        :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
    >
      <el-table-column label="操作日期">
        <template slot-scope="scope">{{ scope.row.createTime | formatDay }}</template>
      </el-table-column>
      <el-table-column label="操作内容" prop="desc" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.isDel == 0">
            耳号{{scope.row.overbit}},
            性别 {{scope.row.gender == 0 ? '♀' : '♂' }} 的小鼠
            从{{scope.row.oldCageNo}}笼舍移到{{scope.row.newCageNo}}笼舍
          </div>
          <div v-else>
            耳号{{scope.row.overbit}},
            性别{{scope.row.gender == 0 ? '♀' : '♂' }}的小鼠
            从{{scope.row.newCageNo}}笼舍移除
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="recall(scope,scope.$index)">撤回操作</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="flex pagination ">
      <el-pagination
          prev-text="<"
          next-text=">"
          @current-change="skipPage"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next, total"
          :total="total">
      </el-pagination>
    </div>

  </el-drawer>

</template>

<script>
export default {
  name: "cageAction",
  props:['subId','isLabAni'],
  data() {
    return {
      drawer: false,
      tableDate: [],
      pageNum:1,
      pageSize:15,
      total:0,
    }
  },
  mounted() {
  },
  methods: {
    //操作记录数据
    getList(){
      // isLabAni : type=1来源实验室，type=0采购，不来源实验室
      this.$get("/mate/log/"+this.subId,
        {
          type:this.isLabAni,
          pageSize:this.pageSize,
          pageNum:this.pageNum
        }
      ).then(res => {
        console.log(res,'进来了')
        if(res.status == 200){
          this.tableDate.push(...res.data.records);
          this.total = res.data.total;
        }
      }).catch(() => {
        this.$message.error("数据获取失败，请重试！")
      })
    },
    //关闭前
    closeAction(){
      this.tableDate = [];
      this.pageNum = 1;
      this.total = 0;
    },
    //撤回操作
    // recall(row,index){
    //   this.tableDate.splice(index,1);//列表删除
    //   //接口删除
    //   this.$post("/mate/log/"+this.subId).then(res => {
    //     if(res.status == 200){
    //       this.tableDate = res.data;
    //     }
    //   }).catch(() => {
    //     this.$message.error("数据获取失败，请重试！")
    //   })
    // },
    // 当前页
    skipPage(current) {
      this.pageNum = current;
      this.getList();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
